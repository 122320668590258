<template>
  <div>
    <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="warning-dialog"
      :visible.sync="adsWarning"
    >
      <div class="row justify-content-xl-center">
        <div class="col-xl-12">
          <img
            style="width: 100%"
            class="img-autosize img-center"
            src="/img/modal/ads_warning.png"
            alt=""
          />
          <br />
          <div
            role="alert"
            class="el-alert el-alert-custom el-alert--warning is-light"
          >
            <div class="el-alert__content">
              <span class="el-alert__title is-bold"
                >Protect your money from fraud and scams</span
              ><!---->
              <p class="el-alert__description">
                We want to remind you to be vigilant regarding potential fraud
                before transferring funds
              </p>
              <br />
              <p class="el-alert__description">
                When making a payment to an individual or organisation, take a
                moment to consider
              </p>
              <p class="el-alert__description">whether:</p>
              <br />
              <p class="el-alert__description">
                - The person you're paying is who you were expecting to<br />
                - The payment is for genuine goods or services<br />
                - The person or business you are paying is legitimate<br />
                - If anything seems off, do not proceed with the transfer
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="agreement"
          >Yes, I acknowledge the warning.</el-checkbox
        >
        <br />
        <el-button @click="adsWarning = false">Cancel</el-button>
        <el-button type="warning" :disabled="!agreement" @click="confirm"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adsWarning: false,
      agreement: false,
    };
  },
  methods: {
    show() {
      this.adsWarning = true;
    },
    hide() {
      this.adsWarning = false;
    },
    confirm() {
      if (this.agreement) {
        this.adsWarning = false;
        this.$emit("confirmed");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
